import React from 'react';

const NotFound = () => (
  <div className="flex bg-light h-screen justify-center items-center">

    <h3 className="text-center text-4xl">Page Not Found</h3>

  </div>
);

export default NotFound;
