import React from 'react';

const Loader = () => (
  <div className="progress__container">
    <div className="progress" />

  </div>
);

export default Loader;
